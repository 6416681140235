import Interweave from "interweave";
import {useEffect, useRef} from "react";
import {videoStoreApi} from "../../../../../store/videos/videoStore";
import ReactPlayer from 'react-player';

import create from "zustand";
import modalStore from "../../../../../store/modals/modalStore";
import audioStore from "../../../../../store/audio/audioStore";
import {modalTypeStore} from "../Modal";

const [modalVideoStore] = create((set) => ({
    videoID: 0,
    playing: false,
    setVideoID: (v) => set({videoID: v}),
    setPlaying: (v) => set({playing: v})
}))

function ModalVideo({content}) {
    const currentVolume = audioStore(state => state.currentVolume)
    useEffect(()=> {
        let video = videoStoreApi.getState().actions.getVideoByID(content.videoID)
        if (!video) return
        let mp4;
        if (video.highBitrateMp4) {
            mp4 = video.highBitrateMp4
        } else {
            mp4 = video.lowBitrateMp4
        }
        setVideoID(mp4)
    }, [])


    useEffect(()=> {
        // console.log('currentVolume', currentVolume);
    }, [currentVolume])

    const playerRef = useRef()
    const id = modalVideoStore(state => state.videoID)
    const setVideoID = modalVideoStore(state => state.setVideoID)
    const playing = modalVideoStore(state => state.playing)
    const setPlaying = modalVideoStore(state => state.setPlaying)
    const closeModal = modalStore(state => state.closeModal)
    const openModal = modalStore(state => state.openModal)
    const setVideoEnded = modalTypeStore(state => state.setVideoEnded)

    useEffect(()=> {
        setPlaying(false)
    }, [closeModal])

    useEffect(()=> {
        if (!closeModal) {
            setPlaying(true)
        }
    }, [openModal])

    function onEnded() {
        console.log('ended');
        setVideoEnded()
    }

    return (
        <div className={`${content.modalVideoFull ? "blah" : ""}`}>
            <ReactPlayer ref={playerRef}
                         volume={parseFloat(currentVolume)} controls={true}
                         loop={false} muted={false} playing={playing} url={`${id}`} width="100%"
                         height={"auto"} playsinline onEnded={onEnded}
            />
            {
                (content.modalSubCopy && !content.modalVideoFull) ?
                    <div className="modaldev__videocopy">
                    <Interweave content={content.modalSubCopy}/>
                    </div>
                    : null
            }
        </div>
    )
}

export default ModalVideo
