import {query_actions} from "./actions";

export const query_galleries = `
  galleries: entries(site: "default") {
    ... on modalGallery_default_Entry {
      id
      title
      galleryCopy
      gallerySlide {
        ... on gallerySlide_slide_BlockType {
          children {
            ... on gallerySlide_slideImage_BlockType {
              galleryImage {
                imageURL: url
              }
            }
            ... on gallerySlide_slideCopy_BlockType {
              copy: galleryCopy
            }
            ... on gallerySlide_slideModel_BlockType {
              galleryModel {
                model: url
              }
            }
          }
          buttons: children {
            ... on gallerySlide_slideButton_BlockType {
              button
              buttonType
              ${query_actions} 
            }
          }
        }
      }
    }
  }
`
