import {query_actions} from "./actions";

export const query_hotspots = `
  hotspots: entries(site: "default") {
    ... on hotspots_default_Entry {
      id
      title
      hotspotHideOnClick
      hotspotChooseIcon {
        id
      }
      hotspotLocation {
        x
        y
        z
      }
      actionBuilderHotspots {
        ... on actionBuilderHotspots_hotspot_BlockType {
          children {
            ... on actionBuilderHotspots_actions_BlockType {
             ${query_actions}
            }
          }
        }
      }
    }
  }
`
