/**
 * Modal store
 *
 */
import create from "zustand";

const [modalStore, modalStoreApi] = create((set, get) => ({
    allModalData: null,
    currentModal: null,
    closeModal: null,
    openModal: null,
    modalGroup: null,
    modalGroupIndex: 0,
    actions: {
        /**
         * All modal data from GraphQL
         * @param modals
         */
        setAllModals(modals) {
            set(state => ({allModalData: processModals(modals) }))
            // console.log('all modals', get().allModalData);
        },
        getModalByID(id) {
            return get().allModalData.find(x => x.id === id)
        },
        setCloseModal() {
            set(state => ({closeModal: true}))
        },
        setOpenModal(modalID, config={}) {
            set(state => ({closeModal: false}))
            set(state => ({
                openModal: {
                    config: config,
                    content: get().actions.getModalByID(modalID),
                    cache: Date.now()
                }
            }))
        },
        /**
         * Used for groups of modals eg help
         * @param group
         */
        setModalGroup(group) {
            // save all modals in group
            set(state => ({
                modalGroup: group
            }))

            set(state => ({modalGroupIndex: 0}))

            let config = {
                isHelp: true,
                total: group.length,
                index: 0
            }
            this.setOpenModal(group[0].id, config)
        },
        getNextModal() {
            // console.log('get next');
            let index = get().modalGroupIndex + 1
            set(state => ({modalGroupIndex: index }))

            let config = {
                isHelp: true,
                total: get().modalGroup.length,
                index: index
            }

            if (index <= get().modalGroup.length-1) {
                this.setOpenModal(get().modalGroup[index].id, config)
            } else {
                this.setCloseModal()
            }

        }
    }
}))

function processModals(modals) {
    let processedModals = []
    modals.forEach(modal => {
        console.log('', modal);
        let processedModal = {}
        processedModal.id = modal.id
        processedModal.title = modal.title
        processedModal.modalAlign = modal.modalAlign
        processedModal.modalColour = modal.modalColour
        processedModal.buttonDirection = modal.buttonDirection
        processedModal.modalResponsive = modal.modalResponsive

        /**
         * Modal with copy
         */
        const content = modal.modalContent.length ? modal.modalContent[0].children[0] : null
        if (content) {
            switch (content.__typename) {
                case "modalContent_copy_BlockType":
                    processedModal.type = "content"
                    processedModal.copy = content.modalCopy
                    processedModal.buttons = content.buttons
                    // console.log('', modal);
                    break
                case "modalContent_video_BlockType":
                    processedModal.type = "video"
                    processedModal.videoID = content.modalVideo[0].id
                    processedModal.buttons = content.buttons
                    processedModal.modalVideoFull = content.modalVideoFull
                    processedModal.modalSubCopy = content.modalSubCopy
                    break
                case "modalContent_gallery_BlockType":
                    // console.log('', processedModal);
                    processedModal.type = "gallery"
                    processedModal.galleryID = content.modalGallery[0].id
                    processedModal.buttons = content.buttons
                    break
                case "modalContent_dModel_BlockType":
                    // console.log('', content.modal3dFile[0].url);
                    processedModal.type = "model"
                    processedModal.modal3D = content.modal3dFile[0].url
                    processedModal.enableBackground = content.enableBackground
                    processedModal.modalSubCopy = content.modalSubCopy
                    processedModal.buttons = content.buttons
                    break
                case "modalContent_collectable_BlockType":
                    // console.log('', content.modal3dFile[0].url);
                    processedModal.type = "collectable"
                    processedModal.collectable = content.modalCollectable[0].id
                    processedModal.modalSubCopy = content.modalSubCopy
                    processedModal.buttons = content.buttons
                    break
                default:
                    processedModal.type = null
            }
        } else { processedModal.type = null }

        processedModals.push(processedModal)
    })
    return processedModals
}

export default modalStore;
export {modalStoreApi}
