import {query_actions} from "./actions";
export const query_modals = `
  modals: entries(section: "modals", site: "default") {
    ... on modals_default_Entry {
      id
      title
      modalAlign
      buttonDirection
      modalColour
      modalResponsive
      modalContent {
        ... on modalContent_mcontent_BlockType {
          children {
            ... on modalContent_copy_BlockType {
              __typename
              modalCopy
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_video_BlockType {
              __typename
              modalVideo {
                ... on videos2d_default_Entry {
                  id
                }
              }
              modalVideoFull
              modalSubCopy
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_gallery_BlockType {
              __typename
              modalGallery {
                ... on modalGallery_default_Entry {
                  id
                }
              }
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_dModel_BlockType {
              __typename
              enableBackground: modal3dBackground
              modal3dFile {
                url
              }
              modalSubCopy
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_collectable_BlockType {
              __typename        
              modalCollectable {
                id
              }
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
          }
        }
      }
    }
  }
`
