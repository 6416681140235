import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import {modalStoreApi} from "../modals/modalStore";
import {sceneStoreApi} from "../scenes/sceneStore";

const [helpStore, helpStoreApi] = create((set, get) => ({
    allHelps: [],
    actions: {
        setAllHelps(helps) {
            set(state => ({ allHelps: processHelps(helps) }))
            // console.log('', get().allHelps);
        },
        getHelpByID(id) {
            return getValueByKey(get().allHelps, "id", id)
        },
        getHelp() {
            // console.log('getting help')
            /**
             * Check to see if the current state has help
             */
            let help = sceneStoreApi.getState().actions.getHelp()

            if (help) {
                let helpSet = this.getHelpByID(help)
                // console.log('', helpSet);
                modalStoreApi.getState().actions.setModalGroup(helpSet.modals)
            } else {
                console.log('no help assigned to state');
            }
        }
    }
}))

function processHelps(helps) {
    let processedHelps = []
    helps.forEach(help => {
        let processedHelp = {}
        processedHelp.id = help.id
        processedHelp.modals = help.helpModalGroup
        processedHelps.push(processedHelp)
    })
    return processedHelps
}

export default helpStore;
export {helpStoreApi}
