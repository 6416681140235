import create from "zustand";
import {findAllByKey, getValueByKey} from "../../utils/utilities";
import {removeGraphQLBlankObjects} from "../../utils/loaders";

const [galleriesStore, galleriesStoreApi] = create((set, get) => ({
    allGalleries: [],
    actions: {
        setAllGalleries(galleries) {
            set(state => ({ allGalleries: processGalleries(galleries) }))
            // console.log('', get().allGalleries);
        },
        getGalleryByID(id) {
            // console.log('', getValueByKey(get().allGalleries, "id", id));
            return getValueByKey(get().allGalleries, "id", id)
        }
    }
}))

function processGalleries(galleries) {
    let processedGalleries = []
    galleries.forEach(gallery => {
        let processedGallery = {}
        processedGallery.id = gallery.id
        processedGallery.title = gallery.title
        processedGallery.copy = gallery.galleryCopy
        let slides = []
        gallery.gallerySlide.forEach(slideObj => {
            let processedSlide = {}
            processedSlide.buttons = []

            let imageURL = findAllByKey(slideObj.children, 'imageURL')[0]
            let copy = findAllByKey(slideObj.children, 'copy')[0]
            let model = findAllByKey(slideObj.children, 'model')[0]
            processedSlide.image = imageURL
            processedSlide.copy = copy
            processedSlide.model = model
            let buttons = removeGraphQLBlankObjects(slideObj.buttons)
            processedSlide.buttons = buttons
            slides.push(processedSlide)

        })
        processedGallery.slides = slides
        processedGalleries.push(processedGallery)
    })
    return processedGalleries
}

export default galleriesStore
export {galleriesStoreApi}
