
// manages the experience
import create from "zustand"
import {useEffect} from "react";
import {usePageVisibility} from 'react-page-visibility';
import {sceneStoreApi} from "../../../store/scenes/sceneStore";
import siteStore from "../../../store/siteStore";
import audioStore from "../../../store/audio/audioStore";

const [experienceStore, experienceStoreApi] = create((set, get) => ({
    currentIndex: 0,
    is2D: false,
    actions: {
        setCurrentIndex(index) {
            set({ currentIndex: index })
        },
        handleHotspotClick(id) {

        },
        setIs2d(v) {
            set({ is2D: v })
        }
    }
}))

function SceneManager() {
    const siteData = siteStore(state => state.siteData);
    const audioActions = audioStore(state => state.actions)
    useEffect(()=> {
        if (!siteData) return
        sceneStoreApi.getState().actions.setScene('4783')
    }, [siteData])

    /**
     * Page visibility
     * used for disabling audio when not focussed
     */
    const isVisible = usePageVisibility();
    useEffect(() => {
        audioActions.setVisible(isVisible)
    }, [isVisible])

    return (
        <></>/*
        <div className="tempscenemanager">
            <h1>SCENE MANAGER</h1>
        </div>*/
    )
}
export default SceneManager
export {experienceStore, experienceStoreApi}
