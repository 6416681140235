import {getQueries} from "../graphql/queries";

export const getAppData = async (query) => {
    let path;
    if (window.location.href.indexOf('localhost') !== -1) {
        path = process.env.REACT_APP_API_LOCAL;
    } else {
        path = process.env.REACT_APP_CRAFT_API;
    }

    const url = `${path}`;

    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: getQueries()
        })
    });
    const res = await response.json();
    return res;
};
