export const query_icons = `
  icons: entries(site: "default") {
    ... on icons_default_Entry {
      id
      hotspotOpacity
      hotspotIcon {
        id
        url
      }
    }
  }
`
