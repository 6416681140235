import Interweave from "interweave";
import {useEffect, useRef} from "react";
import "@google/model-viewer/dist/model-viewer";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination/pagination.min.css";
import create from "zustand";
import {galleriesStoreApi} from "../../../../../store/galleries/galleriesStore";
import ModalActions from "../modalcomponents/ModalActions";
import modalStore from "../../../../../store/modals/modalStore";

export const [modalGalleryStore] = create((set) => ({
    gallery: null,
    galleryIndex: 0,
    galleryButtons: [],
    hideModalButtons: false,
    slideCopy: null,
    modelSlide: false,
    setGallery: (v) => set({gallery: v}),
    setGalleryIndex: (v) => set({galleryIndex: v}),
    setGalleryButtons: (v) => set({galleryButtons: v}),
    setHideModalButtons: (v) => set({hideModalButtons: v}),
    setSlideCopy: (v) => set({slideCopy: v}),
    setModelSlide: (v) => set({modelSlide: v})
}))


function ModalGallery(galleryID) {
    const gallery = modalGalleryStore(state => state.gallery)
    const setGallery = modalGalleryStore(state => state.setGallery)
    const galleryIndex = modalGalleryStore(state => state.galleryIndex)
    const setGalleryIndex = modalGalleryStore(state => state.setGalleryIndex)
    const galleryButtons = modalGalleryStore(state => state.galleryButtons)
    const setGalleryButtons = modalGalleryStore(state => state.setGalleryButtons)
    const setHideModalButtons = modalGalleryStore(state => state.setHideModalButtons)
    const slideCopy = modalGalleryStore(state => state.slideCopy)
    const setSlideCopy = modalGalleryStore(state => state.setSlideCopy)
    const modelSlide = modalGalleryStore(state => state.modelSlide)
    const setModelSlide = modalGalleryStore(state => state.setModelSlide)
    const openModal = modalStore(state => state.openModal)
    const swiperRef = useRef()

    useEffect(() => {
        setGallery(galleriesStoreApi.getState().actions.getGalleryByID(
            galleryID.galleryID
        ))
        return () => {
            document.body.classList.remove("oslide")
            setGalleryIndex(0)
        }
    }, [])

    // check if there are slide buttons which override default
    useEffect(() => {
        if (!gallery) return
        // console.log('', gallery);
        const buttons = gallery.slides[galleryIndex].buttons

        if (buttons.length) {
            setGalleryButtons(buttons)
            // TODO
            document.body.classList.add("oslide")
        } else {
            setGalleryButtons([])
            setHideModalButtons(false)
            // TODO
            document.body.classList.remove("oslide")
        }

        const copy = gallery.slides[galleryIndex].copy
        if (copy) {
            setSlideCopy(copy)
        } else {
            setSlideCopy(null)
        }

        const model = gallery.slides[galleryIndex].model
        if (model) {

            setModelSlide(false)
            swiperRef.current.swiper.update()
        } else {
            setModelSlide(true)
            swiperRef.current.swiper.update()
        }
        // console.log('modelSlide',  swiperRef.current.swiper);

    }, [gallery, galleryIndex])


    function swiperInit() {

    }

    function onSlideChange(e) {
        setGalleryIndex(e.activeIndex)
    }

    function onPrev() {
        swiperRef.current.swiper.slidePrev()
    }
    function onNext() {
        swiperRef.current.swiper.slideNext()
    }

    return (
        <div style={{width: "100%", height: "100%"}} className="gallery">
            {
                gallery ?
                    <div style={{width: "100%", height: "100%"}}>

                        <div style={{width: "100%", height: "100%"}}>
                            <Swiper ref={swiperRef} style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff'
                            }} spaceBetween={10} navigation={false}
                                    className="mySwiper2"
                                    initialSlide={0}
                                    onSwiper={swiperInit}
                                    onSlideChange={onSlideChange}
                                    allowTouchMove={modelSlide}
                            >

                                {gallery.slides.map((item, index) => (

                                    <SwiperSlide key={index}>
                                        {
                                            item.model ?
                                                <div className="gallerymodel">
                                                    <model-viewer src={item.model} alt="" autoplay camera-controls />
                                                </div>
                                                :
                                                <div>
                                                    <div>

                                                    </div>
                                                    <img src={item.image}/>
                                                </div>

                                        }
                                    </SwiperSlide>

                                ))}
                            </Swiper>
                            <div className={`helpcircles helpcircles--gallery ${openModal && openModal.content.modalColour}`}>
                                {[...Array(gallery.slides.length)].map((e, i) => (
                                        <div key={i}
                                             className={`helpcircle ${i == galleryIndex ? "helpcircle--active" : ""}`}
                                        />
                                    )
                                )}
                            </div>
                            <div className="swiper-button-prev" onClick={onPrev}/>
                            <div className="swiper-button-next" onClick={onNext}/>
                            {
                                slideCopy ?
                                    <Interweave content={slideCopy}/>
                                    :
                                    <Interweave content={gallery.copy}/>
                            }
                            {
                                galleryButtons && galleryButtons.length ?
                                    <ModalActions buttons={galleryButtons}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    : null
            }

        </div>
    )
}

export default ModalGallery
