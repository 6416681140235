import {useEffect} from "react";
// import IconHotspot from "../../../three/hotspots/hotspotComponents/IconHotspot";
import {actionsStoreApi} from "../../../../../store/actions/actionStore";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import themeStore from "../../../../../store/themes/themeStore";


function ModalActions({buttons, direction}) {
    const theme = themeStore(state => state.theme)


    useEffect(()=> {
        if (!buttons) return;
        // console.log('', buttons);
    }, [buttons])

    function onClick(index) {
        // TODO: sort this
        let actions = buttons[index].actions
        doActions(actions);
    }

    // `${direction==="vertical" ? 'column' : 'row'}`
    return (
        <div className={`uibutton__container ${direction==="vertical" ? 'uibutton__container--column' : 'uibutton__container--row'}`} >
            {
                buttons && buttons.map((button, index) => (
                    <div className={`uibutton ${button.buttonType === "primary" ? "uibutton__primary" : "uibutton__secondary"}`}
                         style={button.buttonType === "primary" ? theme.buttonStyle : theme.buttonStyleSecondary}
                         onClick={()=> onClick(index)}
                         key={index}>{button.button}</div>
                ))
            }
        </div>
    )
}
export default ModalActions
