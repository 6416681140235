import Interweave from "interweave";
import "@google/model-viewer/dist/model-viewer";
import {useEffect} from "react";

function ModalModel({content}) {
    useEffect(()=> {
        if (!content) return
        // console.log('', content);
        if (!content.enableBackground) {
            document.body.classList.add("removebg")
        }
        return ()=> {
            document.body.classList.remove("removebg")
        }
    }, [content])
    return (
        <>
                <model-viewer src={content.modal3D} alt="" autoplay camera-controls />
            {
                (content.modalSubCopy && !content.modalVideoFull) ?
                    <div className="modaldev__videocopy">
                        <Interweave content={content.modalSubCopy}/>
                    </div>
                    : null
            }
        </>
    )
}

export default ModalModel
