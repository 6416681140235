export const query_videos = `
  twod: entries(section: "videos2d", site: "default") {
    ... on videos2d_default_Entry {
      id
      highBitrateHlsStream
      highBitrateMp4
      lowBitrateHlsStream
      lowBitrateMp4
    }
  }
  threed: entries(section: "videos360", site: "default") {
    ... on videos360_default_Entry {
      id
      highBitrateHlsStream
      highBitrateMp4
      lowBitrateHlsStream
      lowBitrateMp4
    }
  }
`
