export const query_routes = `
  routes: entries(site: "default") {
    ... on routes_default_Entry {
      id
      title
      boomerangSkippable
      routeEnd {
        id
      }
      routeVideo {
        id
      }
      cameraOrientation {
        x
        y
        z
      }
    }
  }
`
