import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import {iconStoreApi} from "../icons/iconsStore";

const [hotspotStore, hotspotStoreApi] = create((set, get) => ({
    allHotspots: [],
    actions: {
        setAllHotspots(hotspots) {
            set(state => ({ allHotspots: processHotspots(hotspots) }))
        },
        getHotspotByID(id) {
            return getValueByKey(get().allHotspots, "id", id)
        }
    }
}))

function processHotspots(hotspots) {
    let processedHotspots = []
    hotspots.forEach(hotspot => {
        // console.log('', hotspot);
        let processedHotspot = {}
        processedHotspot.actions = hotspot.actionBuilderHotspots[0].children[0].actions
        let icon = iconStoreApi.getState().actions.getIconByID(
            hotspot.hotspotChooseIcon[0].id
        )
        processedHotspot.id = hotspot.id
        processedHotspot.icon = icon
        processedHotspot.title = hotspot.title
        processedHotspot.hotspotHideOnClick = hotspot.hotspotHideOnClick
        processedHotspot.hotspotLocation = hotspot.hotspotLocation
        processedHotspots.push(processedHotspot)
    })
    return processedHotspots
}

export default hotspotStore;
export {hotspotStoreApi}
