import create from "zustand";
import {fullScreen, toggleGyro} from "../../../../utils/utilities";
import {audioStoreApi} from "../../../../store/audio/audioStore";
import {modalTypeStoreApi} from "../modal/Modal";
import {globalSetStoreAPI} from "../../../../store/globalSets/globalSetStore";
import {modalStoreApi} from "../../../../store/modals/modalStore";

const [uiStore, uiStoreApi] = create((set, get) => ({
    toggleMenu: false,
    fullScreen: false,
    gyro: false,
    mute: false,
    toggleCollectable: false,
    actions: {
        toggleMenu() {
            console.log('toggle', get().toggleMenu);
            set(state => ({ toggleMenu: !get().toggleMenu }))
        },
        setMenu(v) {
            console.log('setMenu', get().toggleMenu);
            // set(state => ({ toggleMenu: v }))
        },
        toggleFullScreen() {
            set(state => ({ fullScreen: !get().fullScreen }))
            fullScreen()
        },
        setFullScreen(v) {
            set(state => ({ fullScreen: v }))
        },
        toggleGyro() {
            set(state => ({ gyro: !get().gyro }))
            toggleGyro()
        },
        setGyro(v) {
            set(state => ({ gyro: v }))
        },
        toggleMute() {
            set(state => ({ mute: !get().mute }))
            audioStoreApi.getState().actions.toggleMute(!get().mute)
        },
        setMute(v) {
            set(state => ({ mute: v }))
            audioStoreApi.getState().actions.setGlobalVolume(0)
        },
        // send ID to open modal, false to toggle icon active
        setToggleCollectable(v) {
            set(state => ({ toggleCollectable: v }))
            if (v) {
                modalStoreApi.getState().actions.setOpenModal(
                    globalSetStoreAPI.getState().globalSets.experience.primaryCollectable.id
                )
            }
        }
    }
}))

export default uiStore
export {uiStoreApi}
