export const query_audio = `
  audio: entries(site: "default") {
    ... on audio_default_Entry {
      id
      audioLoop
      audioVolume
      audioAsset {
        id
        url
      }
    }
  }
`
