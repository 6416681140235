import create from "zustand";
import {getValueByKey} from "../../utils/utilities";

const [iconStore, iconStoreApi] = create((set, get) => ({
    allIcons: [],
    actions: {
        setAllIcons(icons) {
            set(state => ({ allIcons: processIcons(icons) }))
            // console.log('', get().allIcons);
        },
        getIconByID(id) {
            // console.log('', get().allIcons);
            return getValueByKey(get().allIcons, "id", id)
        }
    }
}))

function processIcons(icons) {
    let processedIcons = []
    icons.forEach(icon => {
        let processedIcon = {}
        processedIcon.id = icon.id
        processedIcon.hotspotTransparency = false
        processedIcon.hotspotOpacity = icon.hotspotOpacity
        processedIcon.url = icon.hotspotIcon[0].url
        processedIcons.push(processedIcon)
    })
    return processedIcons
}

export default iconStore
export {iconStoreApi}
