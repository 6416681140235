export const query_helps = `
  helps: entries(site: "default") {
    ... on help_default_Entry {
      id
      helpModalGroup {
        id
      }
    }
  }
`
